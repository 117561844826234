export const ContactInfoColumns = [
  "contact.title_before_name",
  "contact.first_name",
  "contact.last_name",
  "contact.role",
  "contact.email",
  "contact.address",
  "contact.zip",
  "contact.city",
  "contact.country.name",
  "contact.country.label",
];
